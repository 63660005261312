/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import "../scss/site.scss"
import NewsPanel from "./newsPanel"
import ContactPanel from "./contactPanel"

const Layout = ({ children, page }) => {
  return (
    <>
      <main className={page}>
        <div className='filter--blur'>
          {children}
        </div>
      </main>
      <NewsPanel />
      <ContactPanel />
    </>
  )
}

export default Layout
