import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: ""

    }
    
  }
  componentDidMount() {
    document.querySelector("form").addEventListener("submit", this.handleSubmit)
  }
  

  handleSubmit = e => {
    console.log('test submission')
    e.preventDefault()
    let myForm = document.getElementById("contact")
    let formData = new FormData(myForm)
    if(formData.get('Full Name') && formData.get('Email') && formData.get('Phone')){
      console.log('test fetch')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        const form = document.querySelector("form")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("form").addEventListener("submit", this.handleSubmit);
    }
  }

  render() {

    const checkPhone = () => {
      var submit = document.querySelector('.submit-button');
      var phone = document.querySelector('.phone-input');
      if(onlyNumbers(phone.value)) {
        phone.classList.remove('invalid');
        submit.removeAttribute('disabled')
      } else {
        phone.classList.add('invalid');
        submit.setAttribute('disabled',true)
      }
    }

    const checkEmail = () => {
      var submit = document.querySelector('.submit-button');
      var email = document.querySelector('.email-input');
      if(validEmail(email.value)) {
        email.classList.remove('invalid');
        submit.removeAttribute('disabled','')

      } else {
        email.classList.add('invalid');
        submit.setAttribute('disabled',true)
      }
    }

    function onlyNumbers(str) {
      return /^[0-9.,' ']+$/.test(str);
    } 

    function validEmail(str) {
      return str.includes('@');
    } 

    const formSubmitted = this.state.formSubmitted
    
    if(!formSubmitted){
    return (
      <div className="pt0" >
        <form method="POST" data-netlify="true" id="contact" className="contact--form">
            <input type="hidden" name="form-name" value="contact" />

            <div className='flex m-wrap'>
              <input required={true} name="Full Name" type="text" className="input" placeholder="Name*"/>
            </div> 

            <div className='flex m-wrap'>
              <input required={true} name="Phone" onChange={checkPhone}  type="text" className="input phone-input" placeholder="Phone*" />
            </div>

            <div className='flex m-wrap'>
              <input required={true} name="Email" onChange={checkEmail} type="text" className="input email-input" placeholder="Email*" />
            </div>

            <div className='flex m-wrap'>
              <textarea name="Message" type="text" className="input" placeholder="Message"/>
            </div>
            
            <div className="submit">
              <button type="submit" className="submit-button button input">Submit</button>
            </div>
          </form>
      </div>
    )}else{
      return <div className="form-section thank-you light op-50" id="thank-you"> Thank You for submitting. We'll be in touch soon.</div>
    }
  }
}

export default EnquiryForm
