import * as React from "react"
import { closePanel } from "../utils/panelActions"
import Spacer from "../components/spacer"
import Form from "../components/form"
import { graphql, useStaticQuery } from "gatsby"

const ContactPanel = ({ content }) => {
    const { contact } = useStaticQuery(graphql`
        query ContactQuery {
            contact: datoCmsContact {
                addressOne
                addressTwo
            }
        }
    `)

    return (
        <div className='panel flex flex-wrap' data-panel='contact'>
            <div className='inner flex flex-wrap w-100 overflow-hidden'>
                <div className='mba flex flex-wrap'>
                    <div className='p20 w-100 light flex mba'>
                        <div>
                            <p className='journal large m0 ul inline'>Contact</p>
                        </div>
                        <div className='close--icon mla' onClick={closePanel} role='presentation' />
                    </div>

                    <div className='light p20 mba mt80 m-mt40 w-100'>
                        <div className='max-675'>
                            <h1 className='m0'>Join us in the pursuit of smarter living. Get in touch with our team via the form below to find out more.</h1>
                        </div>
                        <div className='flex mt40 m-wrap'>
                            <div className='w-50 m-100 grey m-mb40'>
                                <div className='mt10'>
                                    General Enquiries
                                    <br />
                                    <a href='mailto:info@trk.com.au' className='link color-link'>
                                        info@trk.com.au
                                    </a>
                                    <br />
                                    <br />
                                    Employment Enquiries
                                    <br />
                                    <a href='mailto:people@trk.com.au' className='link color-link'>
                                        people@trk.com.au
                                    </a>
                                </div>
                            </div>
                            <div className='w-50 m-100 m-mb40'>
                                <Form />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mta w-100 grey p20'>
                    <div className='mb40'>
                        {contact.addressOne}
                        <br />
                        {contact.addressTwo}
                        <br />
                        <a href='tel:30126361' className='link color-link'>
                            (07) 3012 6361
                        </a>
                        <br />
                        <a href='mailto:info@trk.com.au' className='link color-link'>
                            info@trk.com.au
                        </a>
                        <br />© TRK Property Group 2022
                    </div>
                    <div className='small'>We acknowledge the Wurundjeri and Boon Wurrung people of the Eastern Kulin nation on whose country we live and create. We acknowledge the Traditional Custodians of the land that was never ceded and recognise their continuing connection to land, water and community. We pay respect to Elders past, present and future. Always was, always will be.</div>
                    <Spacer className='m-show' />
                </div>
            </div>
        </div>
    )
}

export default ContactPanel
