import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {closePanel} from '../utils/panelActions'
import Spacer from "../components/spacer"

const NewsPanel = () => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      allDatoCmsBlogPost(sort:{fields:position,order:ASC}) {
        nodes {
          externalLink
          publisher
          content
          image {
            gatsbyImageData
          }
          date 
          name
        }
      }
    }
  `)
  var news = data.allDatoCmsBlogPost.nodes;
  return (
  <div className="panel" data-panel="news">
    <div className='flex inner mba w-100 overflow-hidden'>
      <div className="p20 w-100 light flex">
        <div>
          <p className="journal large m0 ul inline">News</p>
        </div>
        <div className='close--icon mla' onClick={closePanel}  role='presentation' />
      </div>
    </div>
     
    <div className='content inner p20 mt80 m-mt40 mba light overflow-hidden'>
      {news?.map((article, index) => {
        return (
          <div key={index} className='pt30 pb30 bt1'>
            <div className='grey-100 mb30 m-mb15'>
              {article.publisher} |  {article.date.split('-')[2]}.{article.date.split('-')[1]}.{article.date.split('-')[0]}
            </div>
            <p className='h1 m0'>{article.name}</p>
            <div className='max-350 mt30'>
              {article.content}
            </div>  
            {(
              article.externalLink ? <a className='link button mt30' target='_blank' rel="noreferrer" href={article.externalLink}>Read More ></a> : '' 
            )}
          </div>
        )}
      )}
      <Spacer className='m-show' />
    </div>
  </div>
  )
}
export default NewsPanel
